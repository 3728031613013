import React, {useState, useEffect} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import {ProjectDemo} from "../ProjectDemo";
import {ProjectsList} from "../../components/ProjectsList";
import {IntlProvider} from "react-intl";
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/ru'
import translations from "../../i18n/locales"


export function AppWrapper() {
    const initialLocale = window.location.search.replace("?locale=", "") || "ru";

    const [state, setState] = useState({
        locale: initialLocale,
        messages: translations[initialLocale]
    });

    const setLocale = (locale) => {
        setState({locale: locale, messages: translations[locale]})
    }

    return (
        <IntlProvider locale={state.locale} key={state.locale} messages={state.messages}>
            <App setLocale={setLocale}/>
        </IntlProvider>
    )
}


function App(props) {
    const [state, setState] = useState({
        projects: []
    });

    useEffect(() => {
        const url = 'https://mlsh.its.xyz/api/v1/projects';
        fetch(url)
            .then(res => res.json())
            .then(res => {
                res.sort((a, b) => (a.order > b.order) ? 1 : (b.order > a.order) ? -1 : 0)
                setState({...state, projects: res})
            })
            .catch(e => {
                console.error(e);
            });
    }, []);

    return (
        <Router>
            <div>
                <Switch>
                    <Route path='/' exact>
                        <ProjectsList projects={state.projects} setLocale={props.setLocale}/>
                    </Route>
                    {state.projects.map(p => (
                        <Route path={`/${p.name}`}>
                            <ProjectDemo project={p} setLocale={props.setLocale}/>
                        </Route>
                    ))}
                </Switch>
            </div>
        </Router>
    );
}
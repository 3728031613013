import './index.css';
import {useEffect, useState} from 'react';
import { Heading } from "../../components/Head";
import { Presets } from "../../components/Presets";
import { Result } from "../../components/Result";
import {Algorithm} from "../../components/Algorithm";
import {FormattedMessage} from "react-intl";


function ProjectDemo(props) {
    const initialState = {
        presets: [],
        selectedImage: '',
        resultImages: Array(0),
        isLoading: false,
        loadTimeSeconds: null,
        processTimeSeconds: null,
        postprocessTimeSeconds: null,
        jsonAnnotation: null
    }
    const [state, setState] = useState(initialState);

    const setSelectedImage = (base64img) => {
        setState({...state, selectedImage: base64img});
    }

     function runDemo() {
        if (state.selectedImage.length === 0) {
            return;
        }

         setState({...state,
             isLoading: true,
             loadTimeSeconds: null,
             processTimeSeconds: null,
             postprocessTimeSeconds: null});

        let request = {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                img_base64: state.selectedImage
            })
        };
        fetch(`https://mlsh.its.xyz/api/v1/projects/${props.project.id}/demo`, request)
            .then(res => res.json())
            .then(function(res) {
                let images = res['images'].map(image => image['img_base64'])
                let load_time = res['load_time']
                let process_time = res['process_time']
                let postprocess_time = res['postprocess_time']
                let annotation = res['annotation']

                setState({...state,
                    isLoading: false,
                    resultImages: images,
                    loadTimeSeconds: load_time,
                    processTimeSeconds: process_time,
                    postprocessTimeSeconds: postprocess_time,
                    jsonAnnotation: annotation
                })
            });
    };

    useEffect(runDemo, [state.selectedImage]);

    return (
        <div className="app">
            <div className="app-contents">
                <FormattedMessage id={`project_name.${props.project.name}`}>
                    {
                        (msg) => <Heading projectName={msg} setLocale={props.setLocale}/>
                    }
                </FormattedMessage>
                {
                    <div>
                        <Presets project={props.project} presets={state.presets}
                                 setSelectedImage={setSelectedImage}/>
                        <Result projectName={props.project.name}
                                inputImage={state.selectedImage}
                                resultImages={state.resultImages}
                                loadTimeSeconds={state.loadTimeSeconds}
                                processTimeSeconds={state.processTimeSeconds}
                                postprocessTimeSeconds={state.postprocessTimeSeconds}
                                isLoading={state.isLoading}
                                jsonAnnotation={state.jsonAnnotation}/>
                        <Algorithm project={props.project}/>
                    </div>
                }
            </div>
        </div>
    );
}

export {
    ProjectDemo
};

import * as React from 'react';
import {Link} from "react-router-dom";
import "./index.css"
import {FormattedMessage, useIntl} from "react-intl";
import {Heading} from "../Head";


function ProjectItem(props) {
    return (
        <div className="project-item">
            <h2 className="project-name">
                <FormattedMessage id={`project_name.${props.project.name}`}/>
            </h2>
            <img src={props.project.preview_image_url}/>
            <div className="project-description">
                {props.project.preview_text}
            </div>
            <Link to={`/${props.project.name}`} className="discover-demo-link">
                <FormattedMessage id="project_list.discover_demo"/> →
            </Link>
        </div>
    )
}


function ProjectsList(props) {
    const intl = useIntl()
    return (
        <div className="app">
            <div className="app-contents">
                <FormattedMessage id="project_list.title">
                    {
                        (msg) => <Heading projectName={msg} setLocale={props.setLocale}/>
                    }
                </FormattedMessage>
                <div className="app-description">
                    <FormattedMessage id="project_list.description"/>
                </div>
                <div className="app-description">
                    <FormattedMessage id="project_list.hint"/>
                    <a href="mailto:presales@its.xyz" className="mailto-link">presales@its.xyz</a>
                </div>
                <div className="projects">
                    {props.projects.map(p => (
                        <ProjectItem project={p}/>
                    ))}
                </div>
            </div>
        </div>

    )
}

export {
    ProjectsList
}
import {Component, createRef, useEffect, useState} from "react";
import "./index.css"
import "@fortawesome/fontawesome-free/css/all.css"
import {FormattedMessage} from "react-intl";


function Presets(props) {
    const [state, setState] = useState({
        presets: []
    });

    useEffect(() => {
        const url = 'https://mlsh.its.xyz/api/v1/presets';
        fetch(url)
            .then(res => res.json())
            .then(res => res.filter(preset => preset.project_id === props.project.id))
            .then(res => {
                res.sort((a, b) => (a.order > b.order) ? 1 : (b.order > a.order) ? -1 : 0)
                setState({...state, presets: res})
            });
    }, []);

    return (
        <div>
            <div className="presets-text">
                <FormattedMessage id="presets.hint"/>
            </div>

            <div className="presets">
                {state.presets.map(p => (
                        <Preset link={p.link} setSelectedImage={props.setSelectedImage}/>
                ))}
                <UploadButton setSelectedImage={props.setSelectedImage}/>
            </div>
        </div>
    )
}

function toBase64(blob) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(blob);

        fileReader.onload = () => {
            resolve(fileReader.result.split(',')[1]);
        }

        fileReader.onerror = (error) => {
            reject(error);
        }
    })
}


class Preset extends Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this)
    }

    onClick() {
        fetch(this.props.link)
            .then(res => res.blob())
            .then(blob => toBase64(blob)
                .then(base64img => {
                    this.props.setSelectedImage(base64img)
                }, err => {
                    console.log(err)
                })
            )

    }

    render() {
        return (
            <div className="preset">
                <button className="preset__button">
                    <img src={this.props.link} className="preset__image"
                         onClick={this.onClick}/>
                </button>
            </div>
        )
    }
}

class UploadButton extends Component {
    constructor(props) {
        super(props);
        this.input = createRef()

        this.onChange = this.onChange.bind(this)
    }

    onChange (event) {
        toBase64(event.target.files[0])
            .then(base64img => {
                this.props.setSelectedImage(base64img)
            }, error => {
                console.log(error)
            })
    }

    render() {
        return (
            <div className="upload">
                <label htmlFor="input_file" className="upload__label">
                    <i className="fas fa-plus fa-5x upload_plus-white"></i>
                </label>
                <input type='file' accept='image/*' className="upload__button" id="input_file"
                       ref={this.input} onChange={this.onChange}/>
            </div>
        )
    }
}


export {
    Presets
}
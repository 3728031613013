import {Carousel} from "react-responsive-carousel";
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import "./index.css"
import {CircularProgress} from '@mui/material';
import {FormattedMessage} from "react-intl";


function Result(props) {
    return (
        <div>
            <div className="results-text">
                <h1 className="results-header">
                    <FormattedMessage id="results.header"/>
                </h1>
                <TimeBlock loadTimeSeconds={props.loadTimeSeconds}
                           processTimeSeconds={props.processTimeSeconds}
                           postprocessTimeSeconds={props.postprocessTimeSeconds}/>
            </div>
            <div className='results'>
                <div className="input">
                    <InputImage inputImage={props.inputImage}/>
                </div>
                <div className="output">
                    <OutputImage resultImages={props.resultImages} isLoading={props.isLoading}/>
                </div>
            </div>
            <DownloadJsonButton json={props.jsonAnnotation}/>
        </div>
    )
}

function TimeBlock (props) {
    let loadTime = props.loadTimeSeconds ? props.loadTimeSeconds.toFixed(3) : null;
    let processTime = props.processTimeSeconds ? props.processTimeSeconds.toFixed(3) : null;
    let postprocessTime = props.postprocessTimeSeconds ? props.postprocessTimeSeconds.toFixed(3) : null;

    if (!(loadTime && processTime && postprocessTime)) {
        return null;
    }
    return (
        <div>
            Loading time: {loadTime} <FormattedMessage id="results.seconds"/><br/>
            Processing time: {processTime} <FormattedMessage id="results.seconds"/><br/>
            Postprocessing time: {postprocessTime} <FormattedMessage id="results.seconds"/><br/>
        </div>
    )
}

function InputImage(props) {
    if (!props.inputImage) {
        return (
            <div>
                <FormattedMessage id="results.hint"/>
            </div>
        )
    }

    return (
        <img src={`data:image/jpg;base64,${props.inputImage}`} className="input__image"/>
    )
}

function OutputImage(props) {
    if (props.isLoading) {
        return <CircularProgress/>
    }
    if (!props.resultImages) {
        return null;
    }

    return (
        <Carousel className="outputCarousel">
            {props.resultImages.map(image => (
                <div>
                    <img src={`data:image/jpg;base64,${image}`}/>
                </div>
            ))}
        </Carousel>
    )
}

function DownloadJsonButton(props) {
    if (!props.json) {
        return null;
    }

    function onClick() {
        let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(props.json));
        let downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href",     dataStr);
        downloadAnchorNode.setAttribute("download", "annotation.json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    return (
        <button className="download-json-button"
                onClick={onClick}>
           <FormattedMessage id="results.download_json"/>
        </button>
    )
}

export {
    Result
}
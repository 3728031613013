import "./index.css"
import {LanguageSwitch} from "../LanguageSwitch"


function Heading(props) {
    return (
        <div className="head">
            <div/>
            <h1 className="title">{props.projectName}</h1>
            <div className="language-switch-container">
                <LanguageSwitch setLocale={props.setLocale}/>
            </div>

        </div>
    )
}

export {
    Heading
}
import * as React from "react";
import "./index.css"
import {useIntl} from "react-intl";

export function LanguageSwitch(props) {
    const intl = useIntl()

    const onChange = (selection) => {
        props.setLocale(selection.target.value)
    }

    return (
        <select className="language-switch__select" onChange={onChange} value={intl.locale}>
            <option>ru</option>
            <option>en</option>
        </select>
    )
}
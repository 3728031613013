import "./index.css"
import {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";


function Algorithm(props) {
    return (
        <div className="algorithm">
            <h1 className="algorithm-header"><FormattedMessage id="algorithm.header"/></h1>
            <Description project={props.project}/>
            <Model project={props.project}/>
            <Publications project={props.project}/>
        </div>
    )
}

function Description(props) {
    const intl = useIntl()

    let stack = intl.locale == "ru" ? props.project.description.stack_ru : props.project.description.stack;
    let dataset = intl.locale == "ru" ? props.project.description.dataset_ru : props.project.description.dataset;
    let training = intl.locale == "ru" ? props.project.description.learning_ru : props.project.description.learning;

    return (
        <div className="algorithm-description">
            <div className="stack">
                <h2 className="algorithm-child-header"><FormattedMessage id="algorithm.stack"/></h2>
                <ul className="algorithm-description__list">
                    {stack.map(technology =>
                        <li>{technology}</li>
                    )}
                </ul>
            </div>
            <div className="dataset">
                <h2 className="algorithm-child-header"><FormattedMessage id="algorithm.dataset"/></h2>
                <ul className="algorithm-description__list">
                    {dataset.map(property =>
                        <li>{property}</li>
                    )}
                </ul>
            </div>
            <div className="training">
                <h2 className="algorithm-child-header"><FormattedMessage id="algorithm.training"/></h2>
                <ul className="algorithm-description__list">
                    {training.map(technique =>
                        <li>{technique}</li>
                    )}
                </ul>
            </div>
        </div>
    )
}

function Model(props) {
    return (
        <>
            <h2 className="algorithm-header">
                <FormattedMessage id="algorithm.model"/>
            </h2>
            <div className="pipeline-schema">
                <img src={props.project.pipeline_schema_url} className="pipeline-schema__image"/>
            </div>
        </>
    )
}


function Publications(props) {
    const initialState = {
        publications: [],
    }

    const [state, setState] = useState(initialState);

    useEffect(() => {
        const url = 'https://mlsh.its.xyz/api/v1/publications';
        fetch(url)
            .then(res => res.json())
            .then(res => res.filter(publication => publication.project_id === props.project.id))
            .then(res => {
                res.sort((a, b) => (a.order > b.order) ? 1 : (b.order > a.order) ? -1 : 0)
                setState({...state, publications: res})
            });
    }, []);

    if (state.publications.length === 0) {
        return null;
    }

    return (
        <>
            <h2 className="algorithm-header">
                <FormattedMessage id="algorithm.publications.header"/>
            </h2>
            {state.publications.map(publication =>
                <div className="publication-body">
                    <span className="publication-body__title">{publication.title}</span>
                    <span className="publication-body__journal">{publication.link_name}</span>

                    <div className="publication-body__authors">
                        <div className="publication-body__author publication-body_author-blue">
                            {publication.authors[0]}
                        </div>
                        {publication.authors.slice(1).map(author => (
                            <div className="publication-body__author publication-body_author-grey">
                                {author}
                            </div>
                        ))}
                    </div>
                    <div className="publications-body__last-row">
                        <div className="publication-body__doi">doi: {publication.doi}</div>
                        <div className="publication-body__buttons">
                            <button className="publications-body__button publications-body__button_grey"
                                    onClick={() => window.location.href=publication.download_link}>
                                <FormattedMessage id="algorithm.publications.download"/>
                            </button>
                            <button className="publications-body__button publications-body__button_blue"
                                    onClick={() => window.location.href=publication.link}>
                                <FormattedMessage id="algorithm.publications.learn_more"/>
                            </button>
                        </div>
                    </div>

                </div>
            )}
        </>
    )
}

export {
    Algorithm
}